<template>
    <div>
      <el-row :gutter="20" class="search-box">
        <el-col :span="4">
          <eb-filter-user-list v-model="query.userId" @change="getList(1)"></eb-filter-user-list>
        </el-col>
  
        <el-col :span="4">
          <el-button type="primary" @click="getList(1)">查询</el-button>
        </el-col>
      </el-row>
  
      <el-table
        border
        :data="dataSource"
        class="page-top-space"
        row-class-name="table-row"
      >
        <el-table-column label="id" prop="id" width="50"></el-table-column>
        <el-table-column label="昵称" prop="nickName"></el-table-column>
        
       
        <el-table-column label="柜子编号" prop="stationMacId"></el-table-column>
  
        <el-table-column label="仓门" prop="doorId"></el-table-column>
  
        <el-table-column label="电池编号" prop="batteryMacId"></el-table-column>

        <el-table-column label="状态" prop="status">
          <template #default="scope">
            <eb-badge
              :list="this.const.RECHARGE_ORDER"
              :target="scope.row.status"
            ></eb-badge>
          </template>
        </el-table-column>
  
        <el-table-column label="结果" prop="result">
          <template #default="scope">
            <eb-badge
              :list="this.const.RECHARGE_ORDER_RESULT"
              :target="scope.row.result"
            ></eb-badge>
          </template>
        </el-table-column>

        <el-table-column label="开始时间" prop="startTime">
          <template #default="scope">
            <eb-badge
              :list="this.const.RECHARGE_ORDER_RESULT"
              :target="scope.row.result"
            ></eb-badge>
          </template>
        </el-table-column>
  
      </el-table>
      <el-pagination
        layout="total, prev, pager, next"
        background
        :total="total"
        :page-size="pages.pageSize"
        :current-page="pages.pageIndex"
        @current-change="getList"
      ></el-pagination>
  
    </div>
  </template>
  
  
  <script>
  import {
    rechargeOrderPage
  } from "@/api/recharge";
  import moment from "moment";
  
  export default {
    data() {
      return {
        dataSource: [],
        modalData: {},
        pages: {
          pageIndex: 1,
          pageSize: 10
        },
        endTime: "",
        expire: -1,
        query: {
          startTime: "",
          endTime: "",
        },
        modalType: "add",
        total: 0,
      };
    },
  
    mounted(){
      this.getList();
    },
  
    methods: {
      changeDate(type) {
        let curDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (type == 2) {
          this.query.startTime = curDate;
          this.query.endTime = "";
        } else if (type == 1) {
          this.query.endTime = curDate;
          this.query.startTime = "";
        } else {
          this.query.endTime = "";
          this.query.startTime = "";
        }
        this.expire = -1;
        this.getList(1);
      },
 
      getList(current) {
        if (!this.validatenull(current)) {
          this.pages.pageIndex = current;
        }
        rechargeOrderPage({ ...this.query, ...this.pages }).then((res) => {
          let list = res.data.data.records || [];
          this.total = res.data.data.total;
          this.dataSource = list;
        });
      },
    },
  };
  </script>
  